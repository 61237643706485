/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';

/* Load AG Grid styling  */
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~ngx-popperjs/css/theme-white.css";
@import "~@ng-select/ng-select/themes/material.theme.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.pointer{
    cursor: pointer;
}

/*
        Override Bootstrap Nav bar color
*/
.mat-toolbar.mat-primary {
    background-color: #CFE2F3;
}
.mat-toolbar.mat-primary a {
    background-color: #CFE2F3;
    color: #2d2d2d !important;
}

/*
 Enhance the material stepper to keep workflow steps stuck at top (is for Outbound Orders)
*/
.mat-horizontal-stepper-header-container{
    position: sticky;
    top: 100px;
    z-index: 2;
}
.mat-horizontal-stepper-header-container{
    background-color: #fafafa !important;
}
/* give extra space at the bottom of steppers to account for ngSelects with search results at the bottom of a form  */
.mat-horizontal-content-container{
    min-height: 550px;
}


/* 
 make ngSelect friendly with reactive forms :-/ 
 */
 /* hide the NG select placeholder so only see 1 */
 .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    display: none !important;
 }
 .ng-select .ng-select-container:after{
     border: none;
 }
 
.line-items-container .product-list .mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 20px;
    background-color: #fff;
    border-top: rgba(0,0,0,.42) 1px solid;
}




 /*
Style ng-select input field to have the form wrap like other material fields
 */
.ng-select .ng-select-container{
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #ced4da !important;
   border-radius: 0.25rem !important;
   transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}